import React from 'react';
import Container from './Container';
import ImageText from './ImageText';
import ImageTextDescription from './ImageTextDesctiption';
import Ad1 from '../images/a1d1-1.svg';
import Ad2 from '../images/a1d1-2.svg';
import Ad3 from '../images/a1d1-3.svg';

export default () => {
  return (
    <div className="content-list-wrapper">
      <div>
        <section className="image-text-section">
          <Container fluid="xl">
            <ImageText image={Ad1} imgWidth={400} imgHeight={380} title="真正实现完整营销链路">
              <ImageTextDescription
                label="一体化广告投放平台"
                content="整合SEM、DSP、信息流、线下广告"
              />
              <ImageTextDescription
                label="打通广告落地触点"
                content="网站、微信、App、小程序、在线咨询"
              />
              <ImageTextDescription
                label="打通应用系统"
                content="直播系统、会员系统、CRM/ERP/OA、客服系统"
              />
              <ImageTextDescription
                label="打通交易平台"
                content="电商平台、自建商城、线下门店、卡券、导购"
              />
              <ImageTextDescription
                label="客户全生命周期"
                content="从潜客、培育、交易到复购的一体化运营"
              />
            </ImageText>
          </Container>
        </section>
        <section className="image-text-section">
          <Container fluid="xl">
            <ImageText image={Ad2} imgWidth={510} imgHeight={380} title="精准的个性化广告投放" position="right">
              <ImageTextDescription label="自动化投放" content="事件驱动重定向和PDB自动流" />
              <ImageTextDescription label="精准人群定向" content="建立基于精准客户画像的DMP人群包" />
              <ImageTextDescription label="AI智能决策" content="实时人群决策引擎实现智能投放" />
              <ImageTextDescription label="全系列优化" content="策略优化、渠道优化、人群优化、投放优化" />
              <ImageTextDescription label="精准大数据" content="主流第三方平台能力加成" />
            </ImageText>
          </Container>
        </section>
        <section className="image-text-section">
          <Container fluid="xl">
            <ImageText image={Ad3} imgWidth={426} imgHeight={353} title="专业的广告策略咨询和运营服务">
              <ImageTextDescription label="一体化营销云+广告的投放管理平台" />
              <ImageTextDescription label="行业标签解决方案和顾问服务" />
              <ImageTextDescription label="广告投放策略咨询和顾问服务" />
              <ImageTextDescription label="资深投放团队提供广告运营服务" />
            </ImageText>
          </Container>
        </section>
      </div>
    </div>
  );
};
