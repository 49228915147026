import React from 'react';
import { List } from 'antd';
import Container from './Container';

const data = [
  { type: 'title', text: 'Ad Hub一体化平台' },
  { type: 'title', text: '传统广告投放管理' },
  { type: 'content', text: '统一的逻辑：前后链数据、产品、流程打通' },
  { type: 'content', text: '广告投放与营销后链路运营的断层' },
  { type: 'content', text: '“乐高式”投放策略搭建，自动化投放管理' },
  { type: 'content', text: '在不同的广告平台进行人工投放' },
  { type: 'content', text: '高阶数据应用，解锁事件驱动型MOT广告' },
  { type: 'content', text: '广告优化手段有限，效果难以反馈' },
  { type: 'content', text: '加持第三方大数据能力' },
  { type: 'content', text: '基于企业现有数据无法形成精准洞察' },
];
function AdvertisingDiff() {
  return (
    <section id="advertising-diff" className="advertising-diff">
      <Container fluid="xl">
        <h3>管理更轻松 投放更精准</h3>
        <h3>Ad Hub 改变广告投放管理模式</h3>
        <List
          grid={{ column: 2 }}
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <div className={`diff-${item.type}`}>{item.text}</div>
            </List.Item>
          )}
        />
      </Container>
    </section>
  );
}

export default AdvertisingDiff;
