import React from 'react';
import Layout from '../components/Layout';
import ProductsBanner from '../components/ProductsBanner';
import VideoTextCard from '../components/VideoTextCard';
import ADHubContentList from '../components/ADHubContentList';
import AdvertisingDiff from '../components/AdvertisingDiff';
import HomeLogos from '../components/HomeLogos';
import BlockIconText from '../components/BlockIconText';
import AdBanner from '../images/ad-banner-image.png';
import VideoHolder from '../images/a1d1hub-video.png';

export default () => (
  <Layout current="products" subCurrent="adhub">
    <ProductsBanner
      title="Ad Hub 智能广告平台"
      description="如何有效衡量和优化广告投放效果，一直是营销人员关注的核心。"
      description1="Ad Hub通过营销全链路的数字化、自动化、智能化，赋能品牌营销的效果升级，全链路一体优化，帮助您挽回“被浪费掉的一半广告费”。"
      img={AdBanner}
      video="https://convertlab-website.oss-cn-hangzhou.aliyuncs.com/videos/a1d1hub.mp4"
      videoId="cl-adhub"
      cover="https://convertlab-website.oss-cn-hangzhou.aliyuncs.com/images/backgroundjpg/video-cover-ad.jpg"
    />
    <VideoTextCard title="更智能更高效的广告效果管理" image={VideoHolder} imgWidth={341} imgHeight={311}>
      <BlockIconText content="从广告投放、营销运营到客户交易的完整链路" color="blue" />
      <BlockIconText content="公域私域一体化的全渠道效果分析和ROI管理" color="green" />
      <BlockIconText content="基于客户全生命周期的广告投放运营策略" color="blue" />
      <BlockIconText content="基于精准客户画像和DMP的个性化广告策略" color="green" />
    </VideoTextCard>
    <ADHubContentList />
    <AdvertisingDiff />
    <HomeLogos />
  </Layout>
);
